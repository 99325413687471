import React, {
  useContext,
  useState,
  useEffect,
  useRef
} from 'react'
import { i18nMoment } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import { Accordion, AccordionContext, useAccordionToggle } from 'react-bootstrap'
import classNames from 'classnames'
import EntrySeparator from './entrySeparator'

interface EntryProps {
  label: string
  createdAt: string
  children: React.ReactNode
  howToOpen?: 'expand' | 'replace'
  defaultIsOpen?: boolean
}

const AccordionToggle = ({ children, setIsOpen }) => {
  const toggle = useAccordionToggle('0')
  const currentEventKey = useContext(AccordionContext)

  return (
    <div
      className='d-flex align-items-center justify-content-center pointer'
      onClick={(e) => {
        setIsOpen(val => !val)
        toggle(e)
      }}
    >
      <div>
        {children}
      </div>
      <CdnSvg
        src={
          currentEventKey === '0'
            ? '/images/chevronDownOutline.svg'
            : '/images/chevronRightOutline.svg'
        }
        className='ml-2 arrow-icon'
      />
    </div>
  )
}

const Expand = ({
  header,
  children,
  setIsOpen,
  isOpen,
}) => (
  <Accordion>
    <AccordionToggle setIsOpen={setIsOpen}>
      {header}
    </AccordionToggle>
    <Accordion.Collapse eventKey='0'>
      {children}
    </Accordion.Collapse>
  </Accordion>
)

const Replace = ({
  header,
  children,
  setIsOpen,
  isOpen,
}) => {
  const toggle = () => setIsOpen(val => !val)
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        contentRef?.current?.scrollIntoView?.({ behavior: 'smooth', block: 'nearest' })
      }, 100)
    }
  }, [isOpen])

  return (
    <div className='d-flex justify-content-center'>
      {!isOpen && (
        <div className='Entry__title--replaceable pointer border-radius p-1' onClick={toggle}>
          {header}
        </div>
      )}

      {isOpen && (
        <div ref={contentRef} className='Entry__replaceable-content w-100'>
          {children}

          <div className='Entry__close-icon' onClick={toggle}>
            <CdnSvg src='/images/xIcon.svg' />
          </div>
        </div>
      )}
    </div>
  )
}

const Entry = ({
  label,
  createdAt,
  children,
  howToOpen = 'expand',
  defaultIsOpen = false,
}: EntryProps) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen)
  const Component = howToOpen === 'expand' ? Expand : Replace

  return (
    <div className={classNames('Entry', `Entry--${howToOpen}`, { 'Entry--open': isOpen })}>
      <EntrySeparator />
      <div className='d-flex flex-column text-center'>
        <Component
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          header={(
            <div className='w-100'>
              <CdnSvg className='mr-2 ai-icon' src='/images/illustrations/aiGradientIcon.svg' />
              <span className='text-secondary text-small'>{label}</span>
              <span className='text-muted text-small ml-2'>{i18nMoment(createdAt).fromNow()}</span>
            </div>
          )}
        >
          {children}
        </Component>
      </div>
      <EntrySeparator />
    </div>
  )
}

export default Entry
