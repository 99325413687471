import React from 'react'
import { SimpleUserType } from 'types/user'
import { i18nMoment } from 'utils/i18nHelpers'
import UserLink from 'components/common/userLink'
import TipTapRichTextView from 'components/common/tiptap/tiptapRichTextView'
import EntrySeparator from 'components/ticketing/view_ticket_sidebar/entries/entrySeparator'

const Message = ({
  user,
  date,
  html,
  userDetails,
}: {
  user?: SimpleUserType
  date: string
  html: string
  userDetails?: {
    name: string
    email: string
  }
}) => (
  <div>
    <EntrySeparator />
    <div className='MessageEntry'>
      <div className='d-flex align-items-center mb-3'>
        <div>
          <UserLink
            user={user}
            avatarSize='24px'
            customClassName='font-weight-500 text-primary'
            unknownUserText={userDetails?.name}
            showUnassignedIcon
          />
        </div>
        <div className='text-small text-secondary font-weight-400 ml-2'>{i18nMoment(date).fromNow()}</div>
      </div>
      <div className='mt-2'>
        <TipTapRichTextView html={html} />
      </div>
    </div>
  </div>
)


export default Message
