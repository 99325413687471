import React from 'react'

const EntrySeparator = () => (
  <div className='Entry__separator d-flex flex-column align-items-center justify-content-center'>
    <div className='Entry__separator__dot' />
    <div className='Entry__separator__line' />
    <div className='Entry__separator__dot' />
  </div>
)

export default EntrySeparator
