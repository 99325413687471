import React from 'react'
import { useSelector } from 'react-redux'
import entitySlice from 'redux/slices/entities'
import API from 'services/api'
import { RootSidebar } from 'components/common/sidebar'
import { TicketType } from 'types/ticketing/ticket'
import useFetch from 'components/common/hooks/useFetch'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import TicketDetailsPanel from 'components/ticketing/view_ticket_sidebar/ticketDetailsPanel'
import EditableTicketDetailsPanel from 'components/ticketing/view_ticket_sidebar/editableTicketDetailsPanel'
import TicketEntries from 'components/ticketing/view_ticket_sidebar/ticketEntries'
import RequestedViaLink from 'components/ticketing/view_ticket_sidebar/requestedViaLink'

type Props = {
  ticketNumber: string | undefined
  onClose: () => void
  isOpen: boolean
  isAdmin?: boolean
}

const ViewTicketSidebar = ({
  ticketNumber, onClose, isOpen, isAdmin = false,
}: Props) => {
  const ticketingApi = isAdmin ? API.admin.ticketing : API.ticketing
  const { data: rawTicket, isLoading, isLoaded } = useFetch<TicketType>(
    () => ticketingApi.tickets.fetch(ticketNumber),
    [ticketNumber],
    {
      load: !!ticketNumber,
      updateEntitySlice: true,
    }
  )

  const ticket = useSelector(entitySlice.selectors.getEntity('ticketingTicket', rawTicket?.id))

  return (
    <RootSidebar
      title={null}
      onClose={onClose}
      isOpen={isOpen}
      className='ViewTicketSidebar'
      ignoredSelectorsWhenClickOutside={['.EntryableModal__Content', '.SmartPdfModal']}
      useBackdrop
    >
      {isLoading || !isLoaded || !ticket ? (
        <CirclesLoadingIndicator />
      ) : (
        <div className='d-flex position-relative'>
          <div className='ViewTicketSidebarLeftColumn border-right h-screen d-flex flex-column flex-grow-1'>
            <h3 className='mb-0'>{ticket.title}</h3>
            <RequestedViaLink ticket={ticket} />
            <TicketEntries ticket={ticket} isAdmin={isAdmin} />
          </div>
          <div className='ViewTicketSidebarRightColumn d-flex flex-column h-screen'>
            {isAdmin ? (
              <EditableTicketDetailsPanel ticket={ticket} />
            ) : (
              <TicketDetailsPanel ticket={ticket} />
            )}
          </div>
        </div>
      )}
    </RootSidebar>
  )
}

export default ViewTicketSidebar
