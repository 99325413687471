import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { TicketOriginEnum, TicketType } from 'types/ticketing/ticket'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.ticketing.view_ticket_sidebar.requested_origin')

export const INTEGRATION_TYPE_MAP = {
  'Ticketing::Integration::Zoho': {
    label: 'Zoho', // Not translated because we always want to show the integration name
    iconSrc: '/images/integrations/zohoSimpleLogo.svg',
  },
}

const RequestedViaLink = ({ ticket }: { ticket: TicketType }) => {
  const { appName } = useCurrentCompany()

  const isIntegrationTicket = ticket.origin === TicketOriginEnum.Integration
  const { externalUrl, integrationType } = ticket

  if (!isIntegrationTicket || !integrationType) {
    return (
      <div className='text-secondary text-small mb-3'>{I18N(ticket.origin, { appName })}</div>
    )
  }

  const { label, iconSrc } = INTEGRATION_TYPE_MAP[integrationType!]

  return (
    <div className='RequestedViaLink d-flex align-items-center text-secondary text-small mb-3'>
      <CdnSvg src={iconSrc} className='d-flex mr-2' />
      <span>{I18N('requested')}&nbsp;</span>
      <a href={externalUrl} target='_blank' rel='noopener noreferrer' className='text-secondary font-weight-400'>
        <span>{I18N('via')} {label}</span>
        <CdnSvg className='ExternalLinkIcon ml-1' src='/images/externalLinkIcon.svg' />
      </a>
    </div>
  )
}

export default RequestedViaLink
