import React, { useState } from 'react'
import classNames from 'classnames'

import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { ButtonSmallNarrow } from 'components/common/buttons'
import { SIMPLE_EDITOR_CONFIGURATION } from 'components/common/tiptap/configurations'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import { SMALL_TOOLBAR_WITH_ATTACHMENTS } from 'components/common/tiptap/toolbar/toolbarVariations'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import { TicketingCommentType } from 'types/ticketing/comment'
import { TicketingMessageType } from 'types/ticketing/message'
import CancelButton from 'components/common/cancelButton'
import ButtonWithIcon from 'components/common/buttons/buttonWithIcon'
import ToggleSwitch from 'components/common/toggleSwitch'

const I18N = i18nPath('views.ticketing.view_ticket_sidebar.create_entry_input')

const ENTRIES_MAP = {
  message: {
    placeholder: I18N('new_message_placeholder'),
    type: 'Ticketing::Message',
    private: false,
  },
  privateComment: {
    placeholder: I18N('new_private_comment_placeholder'),
    type: 'Ticketing::Comment',
    private: true,
  },
  publicComment: {
    placeholder: I18N('new_public_comment_placeholder'),
    type: 'Ticketing::Comment',
    private: false,
  },
}

type CreateEntryParams = {
  content: string
  private?: boolean
}

const CreateEntryInput = ({
  ticket,
  isAdmin,
  newEntryContent,
  setNewEntryContent,
  updateContentMessage,
  entries,
  setEntries,
  editorKey,
  setEditorKey,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const ticketingApi = isAdmin ? API.admin.ticketing : API.ticketing
  const [entryType, setEntryType] = useState(ENTRIES_MAP.message)

  const isComment = entryType.type === 'Ticketing::Comment'
  const isPrivateComment = isComment && entryType.private

  const [createMessage, { isLoading: isCreatingMessage }] = useApi(ticketingApi.messages.create, {
    onSuccess: (message) => {
      addEntry(message)
      updateContentMessage('')
    },
    updateEntitySlice: true,
  })

  // We can only create comments in the admin view
  const [createComment, { isLoading: isCreatingComment }] = useApi(
    isAdmin ? API.admin.ticketing.comments.create : () => Promise.reject(new Error('Unauthorized')),
    {
      onSuccess: (comment) => {
        addEntry(comment)
        updateContentMessage('')
      },
    }
  )

  const addEntry = (entry: TicketingMessageType | TicketingCommentType) => {
    setEntries([
      ...entries,
      {
        id: entry.id,
        entryableId: entry.id,
        ticketId: ticket.id,
        entryableType: entryType.type,
        entryable: entry,
        createdAt: entry.createdAt,
        updatedAt: entry.updatedAt,
        ...('private' in entry && { private: entry.private }),
      },
    ])
  }

  const handleCreateEntry = () => {
    const createFunction = entryType.type === 'Ticketing::Message' ? createMessage : createComment
    const createParams: CreateEntryParams = { content: newEntryContent }
    if (isComment) {
      createParams.private = isPrivateComment
    }
    createFunction(ticket.id, createParams)
  }

  const togglePrivateComment = () => {
    setEntryType(isPrivateComment ? ENTRIES_MAP.publicComment : ENTRIES_MAP.privateComment)
    setEditorKey(prev => prev + 1)
  }

  const isCreatingEntry = isCreatingMessage || isCreatingComment

  const expandEditor = (entryType) => {
    setIsExpanded(true)
    setEntryType(entryType)
    // We need change the editor key to display the correct placeholder.
    // We need to wait for the editor to be expanded before setting the key
    // Otherwise the expansion transition does not work
    setTimeout(() => {
      setEditorKey(prev => prev + 1)
    }, 500)
  }

  const onCancel = () => {
    setNewEntryContent('')
    setIsExpanded(false)
    if (isComment) {
      setTimeout(() => {
        setEntryType(ENTRIES_MAP.message)
        setEditorKey(prev => prev + 1)
      }, 500)
    }
  }

  return (
    <div className='CreateEntryInput'>
      <TiptapEditor
        key={editorKey}
        className={classNames('TipTapEditor bordered', {
          isPublicComment: isComment && entryType.private === false,
          isPrivateComment,
          expanded: isExpanded,
        })}
        editorContentClassName='p-3'
        onChange={html => setNewEntryContent(html)}
        html={newEntryContent}
        configuration={{
          ...SIMPLE_EDITOR_CONFIGURATION,
          imagesEnabled: true,
          filesEnabled: true,
          userMentionsEnabled: true,
          placeholder: entryType.placeholder,
        }}
        toolbarItems={isExpanded ? SMALL_TOOLBAR_WITH_ATTACHMENTS : []}
        handleDOMEvents={{
          focus: () => setIsExpanded(true),
        }}
      />
      {isAdmin && !isExpanded && (
        <div className='d-flex justify-content-end align-items-center gap-2 mt-3'>
          <ButtonWithIcon
            iconSrc='/images/commentIcon.svg'
            variant='secondary'
            onClick={() => expandEditor(ENTRIES_MAP.privateComment)}
            className='CommentButton'
          >
            {I18N('comment')}
          </ButtonWithIcon>
          <ButtonWithIcon
            iconSrc='/images/rightCornerArrowIcon.svg'
            onClick={() => expandEditor(ENTRIES_MAP.message)}
            className='ReplyButton'
          >
            {I18N('reply')}
          </ButtonWithIcon>
        </div>
      )}

      {isExpanded && (
        <div className='d-flex justify-content-between align-items-center mt-2'>
          <div>
            {isComment && (
              <ToggleSwitch
                checked={isPrivateComment}
                text={I18N('private')}
                onChange={togglePrivateComment}
                textPosition='after'
              />
            )}
          </div>
          <div>
            <CancelButton
              onClick={onCancel}
            />
            <ButtonSmallNarrow
              showLoadingSpinner={isCreatingEntry}
              onClick={handleCreateEntry}
              disabled={!newEntryContent || isCreatingEntry}
            >
              {ticket.status === 'closed' ? I18N('send_and_reopen') : I18NCommon('send')}
            </ButtonSmallNarrow>
          </div>
        </div>
      )}
    </div>
  )
}

export default CreateEntryInput
