import moment from 'moment'
import React, { useContext } from 'react'

import { ButtonNarrow } from 'components/common/buttons/button'
import AdminTicketStatusSelect from 'components/admin/ticketing/adminTicketStatusSelect'
import AssigneeSelect from 'components/admin/ticketing/assigneeSelect'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import DatePicker from 'components/form_fields/datePicker'
import UserOrUnassigned from 'components/ticketing/userOrUnassigned'
import TicketDropdown from 'components/ticketing/view_ticket_sidebar/ticketDropdown'
import { TicketOriginEnum } from 'types/ticketing/ticket'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import TicketingContext from 'components/ticketing/ticketingContext'
import useGlobalStateBucket from 'components/common/hooks/useGlobalStateBucket'
import ReactSelect from 'components/common/react_select'
import EmojiOrPlaceholder from 'components/common/emojiOrPlaceholder'

const I18N = i18nPath('views.ticketing.view_ticket_sidebar')

const TicketDetails = ({
  ticket,
  updateTicket,
  isUpdating,
}) => {
  const { fetchCounts } = useContext(TicketingContext)
  const isClosed = ticket.status === 'closed'
  const isIntegrationTicket = ticket.origin === TicketOriginEnum.Integration

  const [closeTicket, { isLoading: isClosing }] = useApi(API.admin.ticketing.tickets.close, {
    onSuccess: fetchCounts,
    updateEntitySlice: true,
    toastSuccessMessage: I18N('ticket_closed'),
    toastErrorMessage: I18NCommon('generic_error'),
  })

  const [reopenTicket, { isLoading: isReopening }] = useApi(API.admin.ticketing.tickets.reopen, {
    onSuccess: fetchCounts,
    updateEntitySlice: true,
    toastSuccessMessage: I18N('ticket_reopened'),
    toastErrorMessage: I18NCommon('generic_error'),
  })

  const { data: departments = [] } = useGlobalStateBucket(API.admin.ticketing.departments.fetchAll, 'ticketingDepartments')

  return (
    <div className='TicketDetails position-relative d-flex flex-column gap-3'>
      <TicketDropdown ticket={ticket} isAdmin className='TicketDropdown position-absolute' />
      <div className='text-small font-weight-600 mb-2'>{I18N('ticket_details')}</div>
      <div className='DetailGroup'>
        <label className='text-small text-secondary'>{I18N('assigned_to')}</label>
        {isIntegrationTicket ? (
          <div>
            <UserOrUnassigned ticket={ticket} fontWeight='500' />
          </div>
        ) : (
          <AssigneeSelect ticket={ticket} />
        )}
      </div>
      <div className='DetailGroup'>
        <label className='text-small text-secondary'>{I18N('department')}</label>
        <ReactSelect
          isDisabled={isIntegrationTicket}
          isClearable
          placeholder={I18NCommon('unassigned')}
          options={departments}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          formatOptionLabel={option => (
            <div className='d-flex align-items-center'>
              <EmojiOrPlaceholder selectedEmoji={option.emojiCode} className='mr-2' />
              <span className='text-small'>{option.name}</span>
            </div>
          )}
          value={ticket.department}
          onChange={department => updateTicket({ id: ticket.id, departmentId: department?.id || null })}
        />
      </div>
      <div className='DetailGroup'>
        <label className='text-small text-secondary'>{I18N('status')}</label>
        <AdminTicketStatusSelect ticket={ticket} />
      </div>
      <div className='DetailGroup'>
        <label className='text-small text-secondary'>{I18N('id')}</label>
        <div className='text-small'>#{ticket.number}</div>
      </div>
      <div className='DetailGroup'>
        <label className='text-small text-secondary'>{I18N('due_date')}</label>
        {isUpdating ? (
          <CirclesLoadingIndicator />
        ) : (
          <DatePicker
            selected={ticket.dueDate ? moment(ticket.dueDate) : undefined}
            onChange={date => updateTicket({ id: ticket.id, dueDate: date?.toISOString() || null })}
            className='w-100'
            isDisabled={isClosed || isIntegrationTicket}
            popperPlacement='bottom-end'
            dateFormat='MMM D, YYYY h:mm A'
          />
        )}
      </div>
      <div>
        {isClosing || isReopening ? (
          <CirclesLoadingIndicator />
        ) : (
          <ButtonNarrow
            onClick={isClosed ? () => reopenTicket(ticket.id) : () => closeTicket(ticket.id)}
            variant={isClosed ? 'secondary' : 'secondary-danger'}
          >
            {isClosed ? I18N('reopen_ticket') : I18N('close_ticket')}
          </ButtonNarrow>
        )}
      </div>
    </div>
  )
}

export default TicketDetails
