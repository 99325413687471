import React from 'react'
import classNames from 'classnames'

import TipTapRichTextView from 'components/common/tiptap/tiptapRichTextView'
import UserLink from 'components/common/userLink'
import { i18nMoment, i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import ClearyTooltip from 'components/common/clearyTooltip'
import EntrySeparator from 'components/ticketing/view_ticket_sidebar/entries/entrySeparator'

const I18N = i18nPath('views.ticketing.view_ticket_sidebar.comment_entry')

const CommentEntry = ({
  entry,
}) => {
  const { createdAt, entryable: { user, content, authorData } } = entry
  const isPrivate = entry.entryable.private
  const iconSrc = isPrivate ? '/images/eyeStrikethroughIcon.svg' : '/images/eyeOpenIcon.svg'

  return (
    <div>
      <EntrySeparator />
      <div className={classNames('CommentEntry', { isPrivate })}>
        <div className='d-flex flex-column'>
          <div className='d-flex mb-3 align-items-center justify-content-between'>
            <div className='d-flex align-items-center'>
              <UserLink
                user={user}
                avatarSize='24px'
                customClassName='font-weight-500 text-primary'
                unknownUserText={authorData?.name}
                showUnassignedIcon
              />
              <div className='text-small text-secondary font-weight-400 ml-2'>{i18nMoment(createdAt).fromNow()}</div>
            </div>

            <ClearyTooltip
              tooltipClassName='PrivateLabelTooltip'
              placement='bottom'
              content={(
                <CdnSvg src={iconSrc} className='d-flex mr-2' />
              )}
            >
              {I18N(isPrivate ? 'private_tooltip' : 'public_tooltip')}
            </ClearyTooltip>
          </div>

          <TipTapRichTextView html={content} />
        </div>
      </div>
    </div>
  )
}

export default CommentEntry
