import React, { useEffect, useState } from 'react'
import {
  SuggestedMessageEntryType,
  TicketEntryType,
  TicketMessageEntryType,
  TicketType,
  WorkflowRunEntryType,
  TicketCommentEntryType
} from 'types/ticketing/ticket'
import useCursorPagination from 'components/common/hooks/useCursorPagination'
import API from 'services/api'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useStickyScroll from 'components/ai/chat/ask_ai_modal/hooks/useStickyScroll'
import CreateEntryInput from 'components/ticketing/view_ticket_sidebar/entries/createEntryInput'
import MessageEntry from './entries/messageEntry'
import CommentEntry from './entries/commentEntry'
import WorkflowRunEntry from './entries/workflowRunEntry'
import SuggestedMessageEntry from './entries/suggestedMessageEntry'

type Props = {
  ticket: TicketType
  isAdmin: boolean
}

const isTicketMessage = (entry: TicketEntryType): entry is TicketMessageEntryType => entry.entryableType === 'Ticketing::Message'
const isWorkflowRun = (entry: TicketEntryType): entry is WorkflowRunEntryType => entry.entryableType === 'Workflow::Run'
const isSuggestedMessage = (entry: TicketEntryType): entry is SuggestedMessageEntryType => entry.entryableType === 'Ticketing::SuggestedMessage'
const isComment = (entry: TicketEntryType): entry is TicketCommentEntryType => entry.entryableType === 'Ticketing::Comment'

const Entry = ({ entry, reloadEntries, updateContentMessage }) => {
  if (isTicketMessage(entry)) {
    return (
      <MessageEntry
        user={entry.entryable.user}
        date={entry.createdAt}
        html={entry.entryable.content}
        userDetails={entry.entryable.userDetails}
      />
    )
  }

  if (isWorkflowRun(entry)) {
    return (
      <WorkflowRunEntry entry={entry} />
    )
  }

  if (isSuggestedMessage(entry)) {
    return (
      <SuggestedMessageEntry
        entry={entry}
        reloadEntries={reloadEntries}
        updateContentMessage={updateContentMessage}
      />
    )
  }

  if (isComment(entry)) {
    return (
      <CommentEntry
        entry={entry}
      />
    )
  }

  return null
}

const TicketEntries = ({ ticket, isAdmin = false }: Props) => {
  const {
    data: entries,
    setData: setEntries,
    isLoading: isLoadingEntries,
    isLoaded: isLoadedEntries,
    onLoadMore: onLoadMoreEntries,
    hasMore: hasMoreEntries,
    resetCursor: resetEntriesCursor,
  } = useCursorPagination<TicketEntryType>(
    API.ticketing.tickets.entries.fetchAll,
    [ticket.id]
  )

  const [editorKey, setEditorKey] = useState(0)
  const [newEntryContent, setNewEntryContent] = useState('')


  const updateContentMessage = (content: string) => {
    setNewEntryContent(content)
    setEditorKey(prevKey => prevKey + 1)
  }

  const { scrollableRef, isScrollAtTheTop } = useStickyScroll()

  useEffect(() => {
    if (isScrollAtTheTop && hasMoreEntries) {
      onLoadMoreEntries()
    }
  }, [isScrollAtTheTop, hasMoreEntries])

  return (
    <div className='TicketEntries d-flex flex-column overflow-y-hidden justify-content-between flex-grow-1'>
      <div className='d-flex flex-column gap-3 overflow-y-auto' ref={scrollableRef}>
        {!isLoadingEntries && !hasMoreEntries && (
          <MessageEntry
            user={ticket.creator}
            date={ticket.createdAt}
            html={ticket.description}
            userDetails={ticket.requesterDetails}
          />
        )}

        {isLoadingEntries && !isLoadedEntries && <CirclesLoadingIndicator />}

        {entries.map(entry => (
          <Entry
            key={entry.id}
            entry={entry}
            reloadEntries={() => {
              resetEntriesCursor()
              setEntries([])
              onLoadMoreEntries()
            }}
            updateContentMessage={updateContentMessage}
          />
        ))}
      </div>

      <div className='mt-4'>
        <CreateEntryInput
          ticket={ticket}
          isAdmin={isAdmin}
          newEntryContent={newEntryContent}
          setNewEntryContent={setNewEntryContent}
          updateContentMessage={updateContentMessage}
          entries={entries}
          setEntries={setEntries}
          editorKey={editorKey}
          setEditorKey={setEditorKey}
        />
      </div>
    </div>
  )
}

export default TicketEntries
